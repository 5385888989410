import afgImage from "../../img/1f1e6-1f1eb.png"
import albImage from "../../img/1f1e6-1f1f1.png"
import algImage from "../../img/1f1e9-1f1ff.png"
import asImage from "../../img/1f1e6-1f1f8.png"
import adImage from "../../img/1f1e6-1f1e9.png"
import aoImage from "../../img/1f1e6-1f1f4.png"
import aiImage from "../../img/1f1e6-1f1ee.png"
import agImage from "../../img/1f1e6-1f1ec.png"
import arImage from "../../img/1f1e6-1f1f7.png"
import amImage from "../../img/1f1e6-1f1f2.png"
import awImage from "../../img/1f1e6-1f1fc.png"
import auImage from "../../img/1f1e6-1f1fa.png"
import atImage from "../../img/1f1e6-1f1f9.png"
import azImage from "../../img/1f1e6-1f1ff.png"
import bsImage from "../../img/1f1e7-1f1f8.png"
import bhImage from "../../img/1f1e7-1f1ed.png"
import bdImage from "../../img/1f1e7-1f1e9.png"
import bbImage from "../../img/1f1e7-1f1e7.png"
import byImage from "../../img/1f1e7-1f1fe.png"
import beImage from "../../img/1f1e7-1f1ea.png"
import bzImage from "../../img/1f1e7-1f1ff.png"
import bjImage from "../../img/1f1e7-1f1ef.png"
import bmImage from "../../img/1f1e7-1f1f2.png"
import btImage from "../../img/1f1e7-1f1f9.png"
import boImage from "../../img/1f1e7-1f1f4.png"
import bqImage from "../../img/1f1e7-1f1f6.png"
import baImage from "../../img/1f1e7-1f1e6.png"
import bwImage from "../../img/1f1e7-1f1fc.png"
import brImage from "../../img/1f1e7-1f1f7.png"
import vgImage from "../../img/1f1fb-1f1ec.png"
import bnImage from "../../img/1f1e7-1f1f3.png"
import bgImage from "../../img/1f1e7-1f1ec.png"
import bfImage from "../../img/1f1e7-1f1eb.png"
import biImage from "../../img/1f1e7-1f1ee.png"
import khImage from "../../img/1f1f0-1f1ed.png"
import cmImage from "../../img/1f1e8-1f1f2.png"
import caImage from "../../img/1f1e8-1f1e6.png"
import cvImage from "../../img/1f1e8-1f1e6.png"
import kyImage from "../../img/1f1f0-1f1fe.png"
import cfImage from "../../img/1f1e8-1f1eb.png"
import tdImage from "../../img/1f1f9-1f1e9.png"
import clImage from "../../img/1f1e8-1f1f1.png"
import cnImage from "../../img/1f1e8-1f1f3.png"
import coImage from "../../img/1f1e8-1f1f4.png"
import kmImage from "../../img/1f1f0-1f1f2.png"
import cdImage from "../../img/1f1e8-1f1e9.png"
import cgImage from "../../img/1f1e8-1f1ec.png"
import ckImage from "../../img/1f1e8-1f1f0.png"
import crImage from "../../img/1f1e8-1f1f7.png"
import ciImage from "../../img/1f1e8-1f1ee.png"
import hrImage from "../../img/1f1ed-1f1f7.png"
import cuImage from "../../img/1f1ed-1f1f7.png"
import cwImage from "../../img/1f1e8-1f1fc.png"
import cyImage from "../../img/1f1e8-1f1fe.png"
import czImage from "../../img/1f1e8-1f1ff.png"
import dkImage from "../../img/1f1e9-1f1f0.png"
import ioImage from "../../img/1f1ee-1f1f4.png"
import djImage from "../../img/1f1e9-1f1ef.png"
import dmImage from "../../img/1f1e9-1f1f2.png"
import doImage from "../../img/1f1e9-1f1f4.png"
import dooImage from "../../img/1f1e9-1f1f4.png"
import dodImage from "../../img/1f1e9-1f1f4.png"
import ecImage from "../../img/1f1ea-1f1e8.png"
import egImage from "../../img/1f1ea-1f1ec.png"
import svImage from "../../img/1f1f8-1f1fb.png"
import gqImage from "../../img/1f1ec-1f1f6.png"
import erImage from "../../img/1f1ea-1f1f7.png"
import eeImage from "../../img/1f1ea-1f1ea.png"
import szImage from "../../img/1f1f8-1f1ff.png"
import etImage from "../../img/1f1ea-1f1f9.png"
import fkImage from "../../img/1f1eb-1f1f0.png"
import foImage from "../../img/1f1eb-1f1f4.png"
import fjImage from "../../img/1f1eb-1f1ef.png"
import fiImage from "../../img/1f1eb-1f1ee.png"
import gfImage from "../../img/1f1ec-1f1eb.png"
import pfImage from "../../img/1f1f5-1f1eb.png"
import gaImage from "../../img/1f1ec-1f1e6.png"
import gmImage from "../../img/1f1ec-1f1f2.png"
import geImage from "../../img/1f1ec-1f1ea.png"
import deImage from "../../img/1f1e9-1f1ea.png"
import ghImage from "../../img/1f1ec-1f1ed.png"
import giImage from "../../img/1f1ec-1f1ee.png"
import grImage from "../../img/1f1ec-1f1f7.png"
import glImage from "../../img/1f1ec-1f1f1.png"
import gdImage from "../../img/1f1ec-1f1e9.png"
import gpImage from "../../img/1f1ec-1f1f5.png"
import guImage from "../../img/1f1ec-1f1fa.png"
import gtImage from "../../img/1f1ec-1f1f9.png"
import gnImage from "../../img/1f1ec-1f1f3.png"
import gwImage from "../../img/1f1ec-1f1fc.png"
import gyImage from "../../img/1f1ec-1f1fe.png"
import htImage from "../../img/1f1ed-1f1f9.png"
import hnImage from "../../img/1f1ed-1f1f3.png"
import hkImage from "../../img/1f1ed-1f1f0.png"
import huImage from "../../img/1f1ed-1f1fa.png"
import isImage from "../../img/1f1ee-1f1f8.png"
import inImage from "../../img/1f1ee-1f1f3.png"
import idImage from "../../img/1f1ee-1f1e9.png"
import irImage from "../../img/1f1ee-1f1f7.png"
import iqImage from "../../img/1f1ee-1f1f6.png"
import ieImage from "../../img/1f1ee-1f1ea.png"
import ilImage from "../../img/1f1ee-1f1f1.png"
import itImage from "../../img/1f1ee-1f1f9.png"
import jmImage from "../../img/1f1ef-1f1f2.png"
import jpImage from "../../img/1f1ef-1f1f5.png"
import joImage from "../../img/1f1ef-1f1f4.png"
import kzImage from "../../img/1f1f0-1f1ff.png"
import keImage from "../../img/1f1f0-1f1ea.png"
import kiImage from "../../img/1f1f0-1f1ee.png"
import xkImage from "../../img/1f1fd-1f1f0.png"
import kwImage from "../../img/1f1f0-1f1fc.png"
import kgImage from "../../img/1f1f0-1f1ec.png"
import lvImage from "../../img/1f1f1-1f1fb.png"
import lrImage from "../../img/1f1f1-1f1f7.png"
import liImage from "../../img/1f1f1-1f1ee.png"
import luImage from "../../img/1f1f1-1f1fa.png"
import mgImage from "../../img/1f1f2-1f1ec.png"
import myImage from "../../img/1f1f2-1f1fe.png"
import mvImage from "../../img/1f1f2-1f1fb.png"
import mlImage from "../../img/1f1f2-1f1f1.png"
import mtImage from "../../img/1f1f2-1f1f9.png"
import mxImage from "../../img/1f1f2-1f1fd.png"
import mdImage from "../../img/1f1f2-1f1e9.png"
import mnImage from "../../img/1f1f2-1f1f3.png"
import maImage from "../../img/1f1f2-1f1e6.png"
import naImage from "../../img/1f1f3-1f1e6.png"
import nlImage from "../../img/1f1f3-1f1f1.png"
import nzImage from "../../img/1f1f3-1f1ff.png"
import ngImage from "../../img/1f1f3-1f1ec.png"
import kpImage from "../../img/1f1f0-1f1f5.png"
import noImage from "../../img/1f1f3-1f1f4.png"
import pkImage from "../../img/1f1f5-1f1f0.png"
import paImage from "../../img/1f1f5-1f1e6.png"
import pyImage from "../../img/1f1f5-1f1fe.png"
import peImage from "../../img/1f1f5-1f1ea.png"
import phImage from "../../img/1f1f5-1f1ed.png"
import plImage from "../../img/1f1f5-1f1f1.png"
import ptImage from "../../img/1f1f5-1f1f9.png"
import roImage from "../../img/1f1f7-1f1f4.png"
import ruImage from "../../img/1f1f7-1f1fa.png"
import rwImage from "../../img/1f1f7-1f1fc.png"
import smImage from "../../img/1f1f8-1f1f2.png"
import saImage from "../../img/1f1f8-1f1e6.png"
import snImage from "../../img/1f1f8-1f1f3.png"
import rsImage from "../../img/1f1f7-1f1f8.png"
import skImage from "../../img/1f1f8-1f1f0.png"
import siImage from "../../img/1f1f8-1f1ee.png"
import krImage from "../../img/1f1f0-1f1f7.png"
import esImage from "../../img/1f1ea-1f1f8.png"
import sdImage from "../../img/1f1f8-1f1e9.png"
import seImage from "../../img/1f1f8-1f1ea.png"
import chImage from "../../img/1f1e8-1f1ed.png"
import syImage from "../../img/1f1f8-1f1fe.png"
import tjImage from "../../img/1f1f9-1f1ef.png"
import thImage from "../../img/1f1f9-1f1ed.png"
import trImage from "../../img/1f1f9-1f1f7.png"
import tmImage from "../../img/1f1f9-1f1f2.png"
import ugImage from "../../img/1f1fa-1f1ec.png"
import uaImage from "../../img/1f1fa-1f1e6.png"
import aeImage from "../../img/1f1e6-1f1ea.png"
import gbImage from "../../img/1f1ec-1f1e7.png"
import uyImage from "../../img/1f1fa-1f1fe.png"
import usImage from "../../img/1f1fa-1f1f8.png"
import uzImage from "../../img/1f1fa-1f1ff.png"
import zmImage from "../../img/1f1ff-1f1f2.png"
import zwImage from "../../img/1f1ff-1f1fc.png"

export const countriesItems = [
    {srcPath: afgImage, alt: '🇦🇫', dataPath: afgImage, countryName: 'Afghanistan', countryCode: '+93'},
    {srcPath: albImage, alt: '🇦🇱', dataPath: albImage, countryName: 'Albania', countryCode: '+355'},
    {srcPath: algImage, alt: '🇩🇿', dataPath: algImage, countryName: 'Algeria', countryCode: '+213'},
    {srcPath: asImage, alt: '🇦🇸', dataPath: asImage, countryName: 'American Samoa', countryCode: '+1684'},
    {srcPath: adImage, alt: '🇦🇩', dataPath: adImage, countryName: 'Andorra', countryCode: '+376'},
    {srcPath: aoImage, alt: '🇦🇴', dataPath: aoImage, countryName: 'Angola', countryCode: '+244'},
    {srcPath: aiImage, alt: '🇦🇮', dataPath: aiImage, countryName: 'Anguilla', countryCode: '+1264'},
    {srcPath: agImage, alt: '🇦🇬', dataPath: asImage, countryName: 'Antigua &amp; Barbuda', countryCode: '+1268'},
    {srcPath: arImage, alt: '🇦🇷', dataPath: arImage, countryName: 'Argentina', countryCode: '+54'},
    {srcPath: amImage, alt: '🇦🇲', dataPath: amImage, countryName: 'Armenia', countryCode: '+374'},
    {srcPath: awImage, alt: '🇦🇼', dataPath: awImage, countryName: 'Aruba', countryCode: '+1684'},
    {srcPath: auImage, alt: '🇦🇺', dataPath: auImage, countryName: 'Australia', countryCode: '+61'},
    {srcPath: atImage, alt: '🇦🇹', dataPath: atImage, countryName: 'Austria', countryCode: '+43'},
    {srcPath: azImage, alt: '🇦🇿', dataPath: azImage, countryName: 'Azerbaijan', countryCode: '+994'},
    {srcPath: bsImage, alt: '🇧🇸', dataPath: bsImage, countryName: 'Bahamas', countryCode: '+1242'},
    {srcPath: bhImage, alt: '🇧🇭', dataPath: bhImage, countryName: 'Bahrain', countryCode: '+973'},
    {srcPath: bdImage, alt: '🇧🇩', dataPath: bdImage, countryName: 'Bangladesh', countryCode: '+880'},
    {srcPath: bbImage, alt: '🇧🇧', dataPath: bbImage, countryName: 'Barbados', countryCode: '+1246'},
    {srcPath: byImage, alt: '🇧🇾', dataPath: byImage, countryName: 'Belarus', countryCode: '+375'},
    {srcPath: beImage, alt: '🇧🇪', dataPath: beImage, countryName: 'Belgium', countryCode: '+32'},
    {srcPath: bzImage, alt: '🇧🇿', dataPath: bzImage, countryName: 'Belize', countryCode: '+501'},
    {srcPath: bjImage, alt: '🇧🇯', dataPath: bjImage, countryName: 'Benin', countryCode: '+229'},
    {srcPath: bmImage, alt: '🇧🇲', dataPath: bmImage, countryName: 'Bermuda', countryCode: '+1441'},
    {srcPath: btImage, alt: '🇧🇹', dataPath: btImage, countryName: 'Bhutan', countryCode: '+975'},
    {srcPath: boImage, alt: '🇧🇴', dataPath: boImage, countryName: 'Bolivia', countryCode: '+591'},
    {srcPath: bqImage, alt: '🇧🇶', dataPath: bqImage, countryName: 'Bonaire, Sint Eustatius &amp; Saba', countryCode: '+599'},
    {srcPath: baImage, alt: '🇧🇦', dataPath: baImage, countryName: 'Bosnia &amp; Herzegovina', countryCode: '+387'},
    {srcPath: bwImage, alt: '🇧🇼', dataPath: bwImage, countryName: 'Botswana', countryCode: '+267'},
    {srcPath: brImage, alt: '🇧🇷', dataPath: brImage, countryName: 'Brazil', countryCode: '+55'},
    {srcPath: vgImage, alt: '🇻🇬', dataPath: vgImage, countryName: 'British Virgin Islands', countryCode: '+1284'},
    {srcPath: bnImage, alt: '🇧🇳', dataPath: bnImage, countryName: 'Brunei Darussalam', countryCode: '+673'},
    {srcPath: bgImage, alt: '🇧🇬', dataPath: bgImage, countryName: 'Bulgaria', countryCode: '+359'},
    {srcPath: bfImage, alt: '🇧🇫', dataPath: bfImage, countryName: 'Burkina Faso', countryCode: '+226'},
    {srcPath: biImage, alt: '🇧🇮', dataPath: biImage, countryName: 'Burundi', countryCode: '+257'},
    {srcPath: khImage, alt: '🇰🇭', dataPath: khImage, countryName: 'Cambodia', countryCode: '+855'},
    {srcPath: cmImage, alt: '🇨🇲', dataPath: cmImage, countryName: 'Cameroon', countryCode: '+237'},
    {srcPath: caImage, alt: '🇨🇦', dataPath: caImage, countryName: 'Canada', countryCode: '+1'},
    {srcPath: cvImage, alt: '🇨🇻', dataPath: cvImage, countryName: 'Cape Verde', countryCode: '+238'},
    {srcPath: kyImage, alt: '🇰🇾', dataPath: kyImage, countryName: 'Cayman Islands', countryCode: '+1345'},
    {srcPath: cfImage, alt: '🇨🇫', dataPath: cfImage, countryName: 'Central African Rep.', countryCode: '+236'},
    {srcPath: tdImage, alt: '🇹🇩', dataPath: tdImage, countryName: 'Chad', countryCode: '+235'},
    {srcPath: clImage, alt: '🇨🇱', dataPath: clImage, countryName: 'Chile', countryCode: '+56'},
    {srcPath: cnImage, alt: '🇨🇳', dataPath: cnImage, countryName: 'China', countryCode: '+86'},
    {srcPath: coImage, alt: '🇨🇴', dataPath: coImage, countryName: 'Colombia', countryCode: '+57'},
    {srcPath: kmImage, alt: '🇰🇲', dataPath: kmImage, countryName: 'Comoros', countryCode: '+269'},
    {srcPath: cdImage, alt: '🇨🇩', dataPath: cdImage, countryName: 'Congo (Dem. Rep.)', countryCode: '+243'},
    {srcPath: cgImage, alt: '🇨🇬', dataPath: cgImage, countryName: 'Congo (Rep.)', countryCode: '+242'},
    {srcPath: ckImage, alt: '🇨🇰', dataPath: ckImage, countryName: 'Cook Islands', countryCode: '+682'},
    {srcPath: crImage, alt: '🇨🇷', dataPath: crImage, countryName: 'Costa Rica', countryCode: '+506'},
    {srcPath: ciImage, alt: '🇨🇮', dataPath: ciImage, countryName: 'Côte dIvoire', countryCode: '+225'},
    {srcPath: hrImage, alt: '🇭🇷', dataPath: hrImage, countryName: 'Croatia', countryCode: '+385'},
    {srcPath: cuImage, alt: '🇨🇺', dataPath: cuImage, countryName: 'Cuba', countryCode: '+53'},
    {srcPath: cwImage, alt: '🇨🇼', dataPath: cwImage, countryName: 'Curaçao', countryCode: '+599'},
    {srcPath: cyImage, alt: '🇨🇾', dataPath: cyImage, countryName: 'Cyprus', countryCode: '+357'},
    {srcPath: czImage, alt: '🇨🇿', dataPath: czImage, countryName: 'Czech Republic', countryCode: '+420'},
    {srcPath: dkImage, alt: '🇩🇰', dataPath: dkImage, countryName: 'Denmark', countryCode: '+45'},
    {srcPath: ioImage, alt: '🇮🇴', dataPath: ioImage, countryName: 'Diego Garcia', countryCode: '+246'},
    {srcPath: djImage, alt: '🇩🇯', dataPath: djImage, countryName: 'Djibouti', countryCode: '+253'},
    {srcPath: dmImage, alt: '🇩🇲', dataPath: dmImage, countryName: 'Dominica', countryCode: '+1767'},
    {srcPath: doImage, alt: '🇩🇴', dataPath: doImage, countryName: 'Dominican Rep.', countryCode: '+1809'},
    {srcPath: dooImage, alt: '🇩🇴', dataPath: dooImage, countryName: 'Dominican Rep.', countryCode: '+1829'},
    {srcPath: dodImage, alt: '🇩🇴', dataPath: dodImage, countryName: 'Dominican Rep.', countryCode: '+1849'},
    {srcPath: ecImage, alt: '🇪🇨', dataPath: ecImage, countryName: 'Ecuador', countryCode: '+593'},
    {srcPath: egImage, alt: '🇪🇬', dataPath: egImage, countryName: 'Egypt', countryCode: '+20'},
    {srcPath: svImage, alt: '🇸🇻', dataPath: svImage, countryName: 'El Salvador', countryCode: '+503'},
    {srcPath: gqImage, alt: '🇬🇶', dataPath: gqImage, countryName: 'Equatorial Guinea', countryCode: '+240'},
    {srcPath: erImage, alt: '🇪🇷', dataPath: erImage, countryName: 'Eritrea', countryCode: '+291'},
    {srcPath: eeImage, alt: '🇪🇪', dataPath: eeImage, countryName: 'Estonia', countryCode: '+372'},
    {srcPath: szImage, alt: '🇸🇿', dataPath: szImage, countryName: 'Eswatini', countryCode: '+268'},
    {srcPath: etImage, alt: '🇪🇹', dataPath: etImage, countryName: 'Ethiopia', countryCode: '+251'},
    {srcPath: fkImage, alt: '🇫🇰', dataPath: fkImage, countryName: 'Falkland Islands', countryCode: '+500'},
    {srcPath: foImage, alt: '🇫🇴', dataPath: foImage, countryName: 'Faroe Islands', countryCode: '+298'},
    {srcPath: fjImage, alt: '🇫🇯', dataPath: fjImage, countryName: 'Fiji', countryCode: '+679'},
    {srcPath: fiImage, alt: '🇫🇮', dataPath: fiImage, countryName: 'Finland', countryCode: '+358'},
    {srcPath: gfImage, alt: '🇬🇫', dataPath: gfImage, countryName: 'French Guiana', countryCode: '+594'},
    {srcPath: pfImage, alt: '🇵🇫', dataPath: pfImage, countryName: 'French Polynesia', countryCode: '+689'},
    {srcPath: gaImage, alt: '🇬🇦', dataPath: gaImage, countryName: 'Gabon', countryCode: '+241'},
    {srcPath: gmImage, alt: '🇬🇲', dataPath: gmImage, countryName: 'Gambia', countryCode: '+220'},
    {srcPath: geImage, alt: '🇬🇪', dataPath: geImage, countryName: 'Georgia', countryCode: '+995'},
    {srcPath: deImage, alt: '🇩🇪', dataPath: deImage, countryName: 'Germany', countryCode: '+49'},
    {srcPath: ghImage, alt: '🇬🇭', dataPath: ghImage, countryName: 'Ghana', countryCode: '+233'},
    {srcPath: giImage, alt: '🇬🇮', dataPath: giImage, countryName: 'Gibraltar', countryCode: '+350'},
    {srcPath: grImage, alt: '🇬🇷', dataPath: grImage, countryName: 'Greece', countryCode: '+30'},
    {srcPath: glImage, alt: '🇬🇱', dataPath: glImage, countryName: 'Greenland', countryCode: '+299'},
    {srcPath: gdImage, alt: '🇬🇩', dataPath: gdImage, countryName: 'Grenada', countryCode: '+1473'},
    {srcPath: gpImage, alt: '🇬🇵', dataPath: gpImage, countryName: 'Guadeloupe', countryCode: '+590'},
    {srcPath: guImage, alt: '🇬🇺', dataPath: guImage, countryName: 'Guam', countryCode: '+1671'},
    {srcPath: gtImage, alt: '🇬🇹', dataPath: gtImage, countryName: 'Guatemala', countryCode: '+502'},
    {srcPath: gnImage, alt: '🇬🇳', dataPath: gnImage, countryName: 'Guinea', countryCode: '+224'},
    {srcPath: gwImage, alt: '🇬🇼', dataPath: gwImage, countryName: 'Guinea-Bissau', countryCode: '+245'},
    {srcPath: gyImage, alt: '🇬🇾', dataPath: gyImage, countryName: 'Guyana', countryCode: '+592'},
    {srcPath: htImage, alt: '🇭🇹', dataPath: htImage, countryName: 'Haiti', countryCode: '+509'},
    {srcPath: hnImage, alt: '🇭🇳', dataPath: hnImage, countryName: 'Honduras', countryCode: '+504'},
    {srcPath: hkImage, alt: '🇭🇰', dataPath: hkImage, countryName: 'Hong Kong', countryCode: '+852'},
    {srcPath: huImage, alt: '🇭🇺', dataPath: huImage, countryName: 'Hungary', countryCode: '+36'},
    {srcPath: isImage, alt: '🇮🇸', dataPath: isImage, countryName: 'Iceland', countryCode: '+354'},
    {srcPath: inImage, alt: '🇮🇳', dataPath: inImage, countryName: 'India', countryCode: '+91'},
    {srcPath: idImage, alt: '🇮🇩', dataPath: idImage, countryName: 'Indonesia', countryCode: '+62'},
    {srcPath: irImage, alt: '🇮🇷', dataPath: irImage, countryName: 'Iran', countryCode: '+98'},
    {srcPath: iqImage, alt: '🇮🇶', dataPath: iqImage, countryName: 'Iraq', countryCode: '+964'},
    {srcPath: ieImage, alt: '🇮🇪', dataPath: ieImage, countryName: 'Ireland', countryCode: '+353'},
    {srcPath: ilImage, alt: '🇮🇱', dataPath: ilImage, countryName: 'Israel', countryCode: '+972'},
    {srcPath: itImage, alt: '🇮🇹', dataPath: itImage, countryName: 'Italy', countryCode: '+39'},
    {srcPath: jmImage, alt: '🇯🇲', dataPath: jmImage, countryName: 'Jamaica', countryCode: '+1876'},
    {srcPath: jpImage, alt: '🇯🇵', dataPath: jpImage, countryName: 'Japan', countryCode: '+81'},
    {srcPath: joImage, alt: '🇯🇴', dataPath: joImage, countryName: 'Jordan', countryCode: '+962'},
    {srcPath: kzImage, alt: '🇰🇿', dataPath: kzImage, countryName: 'Kazakhstan', countryCode: '+7'},
    {srcPath: keImage, alt: '🇰🇪', dataPath: keImage, countryName: 'Kenya', countryCode: '+254'},
    {srcPath: kiImage, alt: '🇰🇮', dataPath: kiImage, countryName: 'Kiribati', countryCode: '+686'},
    {srcPath: xkImage, alt: '🇬🇭', dataPath: xkImage, countryName: 'Kosovo', countryCode: '+383'},
    {srcPath: kwImage, alt: '🇰🇼', dataPath: kwImage, countryName: 'Kuwait', countryCode: '+965'},
    {srcPath: kgImage, alt: '🇰🇬', dataPath: kgImage, countryName: 'Kyrgyzstan', countryCode: '+996'},
    {srcPath: lvImage, alt: '🇱🇻', dataPath: lvImage, countryName: 'Latvia', countryCode: '+371'},
    {srcPath: lrImage, alt: '🇱🇷', dataPath: lrImage, countryName: 'Liberia', countryCode: '+231'},
    {srcPath: liImage, alt: '🇱🇮', dataPath: liImage, countryName: 'Liechtenstein', countryCode: '+423'},
    {srcPath: luImage, alt: '🇱🇺', dataPath: luImage, countryName: 'Luxembourg', countryCode: '+352'},
    {srcPath: mgImage, alt: '🇲🇬', dataPath: mgImage, countryName: 'Madagascar', countryCode: '+261'},
    {srcPath: myImage, alt: '🇲🇾', dataPath: myImage, countryName: 'Malaysia', countryCode: '+60'},
    {srcPath: mvImage, alt: '🇲🇻', dataPath: mvImage, countryName: 'Maldives', countryCode: '+960'},
    {srcPath: mlImage, alt: '🇲🇱', dataPath: mlImage, countryName: 'Mali', countryCode: '+223'},
    {srcPath: mtImage, alt: '🇲🇹', dataPath: mtImage, countryName: 'Malta', countryCode: '+356'},
    {srcPath: mxImage, alt: '🇲🇽', dataPath: mxImage, countryName: 'Mexico', countryCode: '+52'},
    {srcPath: mdImage, alt: '🇲🇩', dataPath: mdImage, countryName: 'Moldova', countryCode: '+373'},
    {srcPath: mnImage, alt: '🇲🇳', dataPath: mnImage, countryName: 'Mongolia', countryCode: '+976'},
    {srcPath: maImage, alt: '🇲🇦', dataPath: maImage, countryName: 'Morocco', countryCode: '+212'},
    {srcPath: naImage, alt: '🇳🇦', dataPath: naImage, countryName: 'Namibia', countryCode: '+264'},
    {srcPath: nlImage, alt: '🇳🇱', dataPath: nlImage, countryName: 'Netherlands', countryCode: '+31'},
    {srcPath: nzImage, alt: '🇳🇿', dataPath: nzImage, countryName: 'New Zealand', countryCode: '+64'},
    {srcPath: ngImage, alt: '🇳🇬', dataPath: ngImage, countryName: 'Nigeria', countryCode: '+234'},
    {srcPath: kpImage, alt: '🇰🇵', dataPath: kpImage, countryName: 'North Korea', countryCode: '+850'},
    {srcPath: noImage, alt: '🇳🇴', dataPath: noImage, countryName: 'Norway', countryCode: '+47'},
    {srcPath: pkImage, alt: '🇵🇰', dataPath: pkImage, countryName: 'Pakistan', countryCode: '+92'},
    {srcPath: paImage, alt: '🇵🇦', dataPath: paImage, countryName: 'Panama', countryCode: '+507'},
    {srcPath: pyImage, alt: '🇵🇾', dataPath: pyImage, countryName: 'Paraguay', countryCode: '+595'},
    {srcPath: peImage, alt: '🇵🇪', dataPath: peImage, countryName: 'Peru', countryCode: '+51'},
    {srcPath: phImage, alt: '🇵🇭', dataPath: phImage, countryName: 'Philippines', countryCode: '+63'},
    {srcPath: plImage, alt: '🇵🇱', dataPath: plImage, countryName: 'Poland', countryCode: '+48'},
    {srcPath: ptImage, alt: '🇵🇹', dataPath: ptImage, countryName: 'Portugal', countryCode: '+351'},
    {srcPath: roImage, alt: '🇷🇴', dataPath: roImage, countryName: 'Romania', countryCode: '+40'},
    {srcPath: ruImage, alt: '🇷🇺', dataPath: ruImage, countryName: 'Russian Federation', countryCode: '+7'},
    {srcPath: rwImage, alt: '🇷🇼', dataPath: rwImage, countryName: 'Rwanda', countryCode: '+250'},
    {srcPath: smImage, alt: '🇸🇲', dataPath: smImage, countryName: 'San Marino', countryCode: '+378'},
    {srcPath: saImage, alt: '🇸🇦', dataPath: saImage, countryName: 'Saudi Arabia', countryCode: '+966'},
    {srcPath: snImage, alt: '🇸🇳', dataPath: snImage, countryName: 'Senegal', countryCode: '+221'},
    {srcPath: rsImage, alt: '🇷🇸', dataPath: rsImage, countryName: 'Serbia', countryCode: '+381'},
    {srcPath: skImage, alt: '🇸🇰', dataPath: skImage, countryName: 'Slovakia', countryCode: '+421'},
    {srcPath: siImage, alt: '🇸🇮', dataPath: siImage, countryName: 'Slovenia', countryCode: '+386'},
    {srcPath: krImage, alt: '🇰🇷', dataPath: krImage, countryName: 'South Korea', countryCode: '+82'},
    {srcPath: esImage, alt: '🇪🇸', dataPath: esImage, countryName: 'Spain', countryCode: '+34'},
    {srcPath: sdImage, alt: '🇸🇩', dataPath: sdImage, countryName: 'Sudan', countryCode: '+249'},
    {srcPath: seImage, alt: '🇸🇪', dataPath: seImage, countryName: 'Sweden', countryCode: '+46'},
    {srcPath: chImage, alt: '🇨🇭', dataPath: chImage, countryName: 'Switzerland', countryCode: '+41'},
    {srcPath: syImage, alt: '🇸🇾', dataPath: syImage, countryName: 'Syria', countryCode: '+963'},
    {srcPath: tjImage, alt: '🇹🇯', dataPath: tjImage, countryName: 'Tajikistan', countryCode: '+992'},
    {srcPath: thImage, alt: '🇹🇭', dataPath: thImage, countryName: 'Thailand', countryCode: '+66'},
    {srcPath: trImage, alt: '🇹🇷', dataPath: trImage, countryName: 'Turkey', countryCode: '+90'},
    {srcPath: tmImage, alt: '🇹🇲', dataPath: tmImage, countryName: 'Turkmenistan', countryCode: '+993'},
    {srcPath: ugImage, alt: '🇺🇬', dataPath: ugImage, countryName: 'Uganda', countryCode: '+256'},
    {srcPath: uaImage, alt: '🇺🇦', dataPath: uaImage, countryName: 'Ukraine', countryCode: '+380'},
    {srcPath: aeImage, alt: '🇦🇪', dataPath: aeImage, countryName: 'United Arab Emirates', countryCode: '+971'},
    {srcPath: gbImage, alt: '🇬🇧', dataPath: gbImage, countryName: 'United Kingdom', countryCode: '+44'},
    {srcPath: uyImage, alt: '🇺🇾', dataPath: uyImage, countryName: 'Uruguay', countryCode: '+598'},
    {srcPath: usImage, alt: '🇺🇸', dataPath: usImage, countryName: 'USA', countryCode: '+1'},
    {srcPath: uzImage, alt: '🇺🇿', dataPath: uzImage, countryName: 'Uzbekistan', countryCode: '+998'},
    {srcPath: zmImage, alt: '🇿🇲', dataPath: zmImage, countryName: 'Zambia', countryCode: '+260'},
    {srcPath: zwImage, alt: '🇿🇼', dataPath: zwImage, countryName: 'Zimbabwe', countryCode: '+263'},
]